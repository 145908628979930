@import "src/static/sass/basic/common";
@import "node_modules/owl.carousel/src/scss/owl.carousel";

.container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: $mobile;
  padding-left: 35px;
  padding-right: 35px;

  @media (min-width: $iphone) {
    width: $iphone;

  }

  @media (min-width: $tablet) {
    width: $tablet;
  }

  @media (min-width: $laptop) {
    width: 880px;
  }
}

.page {
  padding-top: 45px;

  @media (min-width: $tablet) {
    padding-top: 55px;
  }
}

.section {
  display: block;
  position: relative;

  &--gray {
    background-color: #fbf4e9;
    background-image: url("../img/products/products-bg-mobile-left.jpg"),
      url("../img/products/products-bg-mobile-right.jpg");
    background-position: left top,
      right top;
    background-size: 160px 467px;
    background-repeat: no-repeat;
    background-clip: padding-box;
    border-style: solid;
    border-width: 23px 0px 0px;
    border-image: url("../img/products/products-border.png") 23 0 fill repeat;
    z-index: 2;
  }

  @media (min-width: $laptop) {
    &--gray {
      background-image: url("../img/products/products-bg-left.jpg"),
        url("../img/products/products-bg-right.jpg");

      background-size: 405px 515px;
    }

  }

  @media (min-width: $desktop) {
    &--gray {
      background-size: 610px 730px;
    }

  }

  @media (min-width: $monitor) {
    &--gray {
      background-size: 810px 890px;
    }
  }


}

.section__title {
  display: block;
  margin: 0 auto;
  text-align: center;
  @include drina();
  color: #653929;
  font-size: 32px;
  line-height: 1;

  @media (min-width: $tablet) {
    font-size: 40px;
  }

  @media (min-width: $laptop) {
    font-size: 52px;
  }

  @media (min-width: $desktop) {
    font-size: 64px;
  }
}

.mobile-title {
  color: #653929;
  font-size: 36px;
  font-weight: bold;
}


@media (min-width: 300px) {
  .mobile-title {
    font-size: 19.5px;
  }
}

@media (min-width: 768px) {
  .mobile-title {
    font-size: 30px;
  }
}

.section__text {
  display: block;
  color: #653929;
  @include ing();
  font-size: 18px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (min-width: $tablet) {
    font-size: 18px;
  }

  @media (min-width: $laptop) {
    font-size: 18px;
  }

  @media (min-width: $desktop) {
    font-size: 24px;
  }
}

p,
span {
  line-height: 1.4;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #a75214;
}

#g {
  width: 18px;
  height: 30px;
  position: relative;
  left: 11px;
  top: 6px;

  @media (max-width: 1180px) {
    width: 16.7px;
  }

  @media (max-width: 768px) {
    width: 13.6px;
    left: 9px;
  }
}
.applying {
  display: block;
}

.applying__list {
  display: block;

  @media (min-width: $laptop) {
    width: 310px;
  }
}

.applying__item {
  display: block;
  position: relative;
  font-size: 16px;
  margin-bottom: 15px;
  padding-left: 20px;

  &::before {
    content: "";
    display: block;
    width: 15px;
    height: 13px;
    position: absolute;
    top: 2px;
    left: -5px;
    background-image: url("../img/recipes-content/healthy.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media (min-width: $tablet) {
    font-size: 16px;
  }

  @media (min-width: $laptop) {
    margin-bottom: 35px;
    padding-left: 35px;
    font-size: 20px;

    &::before {
      width: 25px;
      height: 22px;
      top: 4px;
    }
  }
}
.brand-content {
  display: block;
  padding-top: 25px;
  padding-bottom: 55px;
  @include ing();

  @media (min-width: $laptop) {
    padding-top: 50px;
    padding-bottom: 140px;
  }
}

.brand-content__info {
  display: block;
  position: relative;
  color: #653929;


}

.brand-content__big-text {
  display: block;
  margin-right: -20px;
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1.4;

  @media (min-width: $tablet) {
    font-size: 24px;
  }

  @media (min-width: $laptop) {
    font-size: 36px;
    margin-bottom: 45px;
  }
}

.brand-content__text {
  display: block;

  @media (min-width: $tablet) {
    &:nth-child(2) {
      padding-right: 350px !important;
    }
  }

  @media (min-width: $laptop) {
    &:nth-child(2) {
      padding-right: 430px !important;
    }

    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }
}

.brand-content__img {
  display: block;
  height: 250px;
  margin: 0 auto;
  margin-top: -15px;
  margin-bottom: -15px;

  @media (min-width: $tablet) {
    height: 280px;
    position: absolute;
    top: 55px;
    right: 50px;
  }

  @media (min-width: $laptop) {
    width: 374px;
    height: 367px;
    top: 140px;
  }
  @media (min-width: 1440px) {
    height: 467px;
  }
}
.faq-content {
  display: block;
  padding-top: 45px;
  padding-bottom: 65px;

  @media (min-width: $tablet) {
    padding-top: 65px;
  }

  @media (min-width: $laptop) {
    padding-bottom: 117px;
  }
}

.faq-content__container {
  width: $mobile;
  margin: 0 auto;

  @media (min-width: $laptop) {
    width: 840px;
  }
}

.faq-content__block {
  display: block;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -20px;

  @media (min-width: $laptop) {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .faq-content__img {
    left: -35px;
  }

  .faq-content__bg {
    right: -10px;
  }

  &:nth-child(1) {
    @media (min-width: $laptop) {
      margin-bottom: 48px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-1.png");

      @media (min-width: $laptop) {
        top: 107px;
      }
    }
  }

  &:nth-child(2) {
    @media (min-width: $laptop) {
      margin-bottom: 128px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-2.png");

      @media (min-width: $laptop) {
        top: 187px;

        padding-bottom: 150px;
      }
    }
  }

  &:nth-child(3) {
    @media (min-width: $laptop) {
      margin-bottom: 180px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-3.png");

      @media (min-width: $laptop) {
        top: 230px;

        padding-bottom: 150px;
      }
    }
  }

  &:nth-child(4) {
    @media (min-width: $laptop) {
      margin-bottom: 128px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-4.png");

      @media (min-width: $laptop) {
        top: 242px;

        padding-bottom: 110px;
      }
    }
  }

  &:nth-child(5) {
    @media (min-width: $laptop) {
      margin-bottom: 117px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-5.png");

      @media (min-width: $laptop) {
        top: 228px;

        padding-bottom: 110px;
        padding-right: 45px;
      }
    }
  }

  &:nth-child(6) {
    @media (min-width: $laptop) {
      margin-bottom: 421px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-6.png");

      @media (min-width: $laptop) {
        top: 191px;
      }
    }
  }

  &:nth-child(7) {
    @media (min-width: $laptop) {
      margin-bottom: 48px;
    }

    .faq-content__bg {
      background-image: url("../img/faq-content/faq-bg-7.png");

      @media (min-width: $laptop) {
        top: 168px;
      }
    }

  }
}

.faq-content__block--right {
  display: block;

  @media (min-width: $tablet) {
    left: -60px;

    .faq-content__img {
      left: -35px;
    }

    .faq-content__bg {
      right: -115px;
    }
  }

  @media (min-width: $laptop) {
    left: auto;
    right: -140px;

    .faq-content__img {
      left: 0;
    }

    .faq-content__bg {
      right: auto;
      left: -228.5px;
      top: 150px;
    }
  }
}

.faq-content__block--left {
  display: block;

  @media (min-width: $tablet) {
    right: -60px;

    .faq-content__img {
      left: auto;
      right: -35px;
    }

    .faq-content__bg {
      right: auto;
      left: -115px;
    }
  }

  @media (min-width: $laptop) {
    right: auto;
    left: -140px;

    .faq-content__img {
      right: 0;
    }

    .faq-content__bg {
      left: auto;
      right: -232px;
      top: 140px;
    }
  }
}

.faq-content__bg {
  display: block;
  position: relative;
  top: -50px;
  z-index: 2;
  padding: 20px;
  padding-bottom: 75px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #653929;
  font-size: 16.5px;

  @media (min-width: $tablet) {
    padding: 25px;
    padding-bottom: 75px;
  }

  @media (min-width: $laptop) {
    position: absolute;
    width: 460px;
    padding: 35px;
    padding-bottom: 95px;
    font-size: 24px;
  }
}

.faq-content__title {
  display: block;
  @include ingBold();
  margin-bottom: 7px;
}

.faq-content__text {
  display: block;
  @include ing();

  li {
    margin-top: 3px;
    padding-bottom: 3px;
  }
}

.faq-content__img {
  display: block;
  position: relative;
}
.features {
  display: block;
  margin-bottom: 10px;
}

.features__list {
  display: flex;
  max-width: 325px;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;

  @media (min-width: $tablet) {
    max-width: 676px;
  }

  @media (min-width: $laptop) {
    max-width: 1200px;
    justify-content: space-between;
  }
}

.features__feature {
  display: block;
  max-width: 120px;
  width: 100%;
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 17px;
  text-align: center;

  @media (min-width: $tablet) {
    max-width: 81px;
  }


  @media (min-width: $laptop) {
    max-width: 200px;
    width: 180px;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.features__feature--width {
  max-width: 131px;

  @media (min-width: $laptop) {
    max-width: 278px;
    width: 100%;
  }
}


.features__image {
  display: block;
  max-width: 50px;
  margin: 0 auto;
  margin-bottom: 6px;

  @media (min-width: $laptop) {
    max-width: 130px;
  }
}

.features__text {
  display: block;
  @include ingBold();
  color: #653929;
  font-size: 13px;
  line-height: 1;

  @media (min-width: $laptop) {
    font-size: 28px;
    @include ingBold();
  }
}

.features__gluten {
  display: block;
}

.features__sugar {
  display: block;
}

.features__gmo {
  display: block;
}

.features__vegan {
  display: block;
}

.features__lactose {
  display: block;
}
.footer {
  display: block;
  position: relative;
  margin-top: -23px;
  padding-top: 35px;
  padding-bottom: 70px;
  background-color: #fff;
  background-clip: padding-box;
  border-style: solid;
  border-width: 23px 0px 0px;
  border-image: url("../img/footer/footer-border.png") 23 0 fill repeat;
  z-index: 2;

  @media (min-width: $laptop) {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

.footer__container {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  align-items: center;

  @media (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  @media (min-width: $laptop) {
    padding-left: 220px;
  }

  @media (min-width: $desktop) {
    max-width: 1400px;
    padding-left: 0;
  }
}

.footer__logo {
  display: block;
  max-width: 170px;
  margin: 0 auto;
  margin-bottom: 25px;

  @media (min-width: $tablet) {
    margin: 0;
  }

  @media (min-width: $laptop) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (min-width: $desktop) {
    position: static;
    transform: none;
    margin-right: 30px;
  }
}

.footer__logo-img {
  display: block;
}

.footer__content {
  display: block;
  margin-bottom: 40px;

  @media (min-width: $tablet) {
    margin-bottom: 0;
  }

  @media (min-width: $laptop) {
    text-align: left;
  }

  @media (min-width: $desktop) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 940px;
  }
}

.footer__copyright {
  display: block;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (min-width: $tablet) {
    margin-bottom: 20px;
  }

  @media (min-width: $laptop) {
    max-width: 360px;
    margin-left: 0;
  }

  @media (min-width: $desktop) {
    margin-bottom: 0;
  }
}

.footer__copyright-text {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: $black;

  @media (min-width: $laptop) {
    font-size: 24px;
  }
}

.footer__links {
  display: block;
  color: #00963F;
  font-size: 18px;
}

.footer__info {
  display: block;
  margin-bottom: 25px;
  color: #00963F;
  text-decoration: none;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    color: #00963F;
  }

  @media (min-width: $tablet) {
    margin-bottom: 10px;
  }

  @media (min-width: $laptop) {
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
    font-size: 24px;
  }

  @media (min-width: $desktop) {
    margin-bottom: 0;
    color: #000;

    &:hover,
    &:active,
    &:focus {
      color: #000;
    }
  }
}

.footer__feedback {
  display: block;
  color: #00963F;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus {
    color: #00963F;
  }

  @media (min-width: $laptop) {
    display: inline-block;
    vertical-align: top;
    font-size: 24px;
  }

  @media (min-width: $desktop) {
    @include ingBold();
    color: #000;

    &:hover,
    &:active,
    &:focus {
      color: #000;
    }
  }
}

.footer__socials {
  display: block;
  font-size: 0;
}

.footer__social {
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &--vk {
    margin-right: 53px;
    background-image: url("../img/footer/vk.svg");
  }

  &--fb {
    background-image: url("../img/footer/fb.svg");
  }

  @media (min-width: $desktop) {
    &--vk {
      margin-right: 15px;
    }
  }
}
.goods {
  display: block;

}

.goods__info {
  padding-top: 15px;

  @media (min-width: $laptop) {
    padding-top: 40px;
  }
}

.goods__title {
  display: block;
  margin-bottom: 20px;

  @media (min-width: $laptop) {
    margin-bottom: 45px;
  }
}

.goods__features {
  @media (min-width: $laptop) {
    margin-bottom: 45px;
  }
}
.header {
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

}

.header__logo {
  display: none;

  @media (min-width: $laptop) {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -610px;
  }

  @media (min-width: $desktop) {
    margin-left: -720px;
  }

  @media (min-width: $monitor) {
    left: 3%;
    margin: 0;
  }

  @media (min-width: 2200px) {
    left: 5%;
  }
}

.header__logo-img {
  display: block;
}

.header__mobile {
  display: block;
  position: relative;
  z-index: 3;
  width: 100%;
  background: linear-gradient(360deg, #8BAE00 0%, #5BA741 100%);
  box-shadow: 0px 2px 6px rgba(46, 0, 0, 0.51);

  @media (min-width: $laptop) {
    display: none;
    background: none;
    box-shadow: none;
  }
}

.header__mobile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  max-width: 90%;
  height: 45px;
  margin: 0 auto;

  @media (min-width: $tablet) {
    height: 55px;
  }

  @media (min-width: $laptop) {
    display: none;
  }
}

.header__mobile-logo {
  display: block;
  width: 88px;
  height: 31px;
  cursor: pointer;

  @media (min-width: $tablet) {
    width: 100px;
    height: 40px;
  }

  @media (min-width: $laptop) {
    display: none;
  }
}

.header__mobile-borges {
  display: block;
  max-width: 100%;
}

.header__mobile-title {
  display: block;
}

.header__mobile-natura {
  display: block;
  width: 95px;
  height: 21px;
  background-image: url("../img/header/natura.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-left: -30px;

  @media (min-width: $tablet) {
    width: 110px;
    height: 30px;
  }

  @media (min-width: $laptop) {
    display: none;
  }
}

.header__mobile-menu {
  display: block;
  width: 45px;
  height: 45px;


  @media (min-width: $tablet) {
    width: 50px;
    height: 50px;
  }

  @media (min-width: $laptop) {
    display: none;
  }
}

.header__burger {
  display: block;
  width: 45px;
  height: 45px;
  background-image: url("../img/header/burger.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  @media (min-width: $tablet) {
    width: 50px;
    height: 50px;
  }
}

.header__close {
  display: none;
  width: 45px;
  height: 45px;
  background-image: url("../img/header/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: relative;
  right: -10px;

  @media (min-width: $tablet) {
    width: 50px;
    height: 50px;
  }
}

.header__menu {
  display: block;

  @media (min-width: $laptop) {
    background-color: #EFD5AD;
    background-clip: padding-box;
    background-image: none;
    box-shadow: none;
    border-style: solid;
    border-width: 0px 0px 23px;
    border-image: url("../img/header/menu-border.png") 23 0 fill repeat;
    border-top: 0;
    border-left: 0;
    border-right: 0
  }

  @media (min-width: $monitor) {
    border-width: 0px 0px 20px;
  }
}

.header__menu-overlay {
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(43, 11, 0, 0.7);

  @media (min-width: $laptop) {
    display: none !important;
  }
}

.header__menu-list {
  display: none;
  padding-top: 15px;
  position: relative;
  z-index: 2;
  background-color: #EFD5AD;

  @media (min-width: $laptop) {
    display: flex !important;
    flex-direction: row;
    max-width: 840px;
    margin: 0 auto;
    position: relative;
    left: 50px;
    font-size: 0;
    padding-top: 9px;
  }

  @media (min-width: $desktop) {
    max-width: 1060px;
    left: 24px;
  }

  @media (min-width: $monitor) {
    left: 15%;
    margin: 0;
  }
}

.header__menu-item {
  display: block;
  text-align: center;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(43, 11, 0, 0.1);
  }



  &--active .header__menu-link {
    background-color: #a75214;
    color: #EFD5AD;

    &:hover,
    &:focus,
    &:active {
      color: #EFD5AD;
    }
  }

  @media (min-width: $laptop) {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;

    &:first-child {
      display: none;
    }

    &:nth-child(2) {
      order: 4;
    }

    &:not(:last-child) {
      border-bottom: none;
    }

    &--active {

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 25px;
        height: 100%;
      }

      &::before {
        left: 0;
        border-top: 18px solid #A75214;
        border-bottom: 18px solid #A75214;
        border-left: 10px solid transparent;
        border-right: 10px solid #A75214;
      }

      &::after {
        right: 0;
        border-top: 18px solid #A75214;
        border-bottom: 18px solid #A75214;
        border-left: 10px solid #A75214;
        border-right: 10px solid transparent;
      }


    }

    @media (min-width: $desktop) {
      padding-left: 27px;
      padding-right: 27px;

      &--active {

        &::before,
        &::after {
          width: 27px;
        }

        &::before {
          left: 0;
          border-top: 20px solid #A75214;
          border-bottom: 20px solid #A75214;
          border-left: 10px solid transparent;
          border-right: 10px solid #A75214;
        }

        &::after {
          right: 0;
          border-top: 20px solid #A75214;
          border-bottom: 20px solid #A75214;
          border-left: 10px solid #A75214;
          border-right: 10px solid transparent;
        }
      }
    }
  }

  @media (min-width: $laptop) {
    display: inline-block;
    vertical-align: top;
  }
}

.header__menu-link {
  display: block;
  padding: 15px 0;
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  color: #653929;
  transition-property: color;
  @include ingBold();

  &:hover {
    color: #a75214;
  }

  &:focus,
  &:active {
    color: #653929;
  }

  @media (min-width: $tablet) {
    font-size: 24px;
    padding: 20px 0;
  }

  @media (min-width: $laptop) {
    font-size: 24px;
    padding: 6px 0;
  }

  @media (min-width: $desktop) {
    font-size: 30px;
    padding: 5px 0;
  }
}
@media (min-width: 300px) {

  .health-content {
    display: block;
    color: #653929;
  }

  .milks {
    margin-top: 17.5px;
  }

  .milks__title-text {
    margin-top: 17.5px;
    font-size: 18px;
  }

  .milks__info {
    font-size: 17px;
  }

  .milks__info--fst {
    margin-bottom: 17px;
  }

  .milk__list {
    margin-top: 41.5px;
  }

  .milk__list-item {
    background: url("../img/health-content/milk-bg.png") no-repeat center;
    -webkit-background-size: contain;
    background-size: contain;
    height: 840px;
    background-position: 50% 101%;
    padding: 0 10px;
    position: relative;
  }

  .milk__img {
    display: block;
    margin: 0 auto;
    width: 135px;
    height: 320px;
  }

  .milk__img-info {
    position: relative;
  }

  .milk__img-text {
    color: #fff;
    width: 90px;
    height: 90px;
    position: absolute;
    top: 210px;
    margin-left: -159px;
    left: 59%;
    background: url("../img/health-content/borg-text.png") no-repeat center;
    -webkit-background-size: contain;
    background-size: contain;
    font-weight: bold;
    text-align: center;
  }

  .milk__img-good {
    position: absolute;
    bottom: -16px;
    left: 49%;
    width: 122px;
  }

  .milk__img-good--size {
    width: 80px;
    left: 53%;

    @media (max-width: 768px) {
      bottom: -29px;
      left: 60%;
    }
  }

  .bold-text {
    font-size: 13.5px;
    line-height: 1;
    text-transform: uppercase;
  }

  .milk__green {
    width: 85px;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 12px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .milk__item-title {
    font-family: drina;
    color: #ED2024;
    font-size: 32px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .milk__item-text {
    font-family: drina;
    font-size: 26px;
    text-align: center;
  }

  .milk__ingredients-title {
    margin-bottom: 17px;
  }

  .milk__composition {
    font-weight: bold;
    padding: 0 15px;
    font-size: 14.5px;
  }

  .milk__composition-item {
    font-weight: normal;
    font-size: 16px;
    margin: 4px 0;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .milk__composition-item::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    background: #653929;
    margin-right: 5px;
  }

  .milk__full-descr {
    display: block;
    text-align: center;
    color: #00963F;
    margin-top: 35px;
  }

  .milk__ingredients-list {
    margin-top: 40px;
  }

  .milks__info--last {
    margin-bottom: 50px;
  }

  .milks__info--sec {
    margin: 17px 0;
  }

  .milks__info--s {
    margin: 17px 0;
  }

  .milk__ingredients-title--th {
    margin: 17px 0;
  }

  .health-content .features {
    padding-bottom: 41px;
  }

}

@media (min-width: 767px) {
  .milk__composition {
    margin: 0 auto;
    width: 289px;
  }

  .milks__title-text {
    margin: 25px 0 30px 0;
    font-size: 18px;
  }

  .milk__item-wrap {
    margin-top: -63px;
  }

  .milk__list-item {
    -webkit-background-size: cover;
    background-size: cover;
    width: 520px;
    height: 970px;
    margin: 0 auto;
    margin-bottom: 70px;
    margin-top: 90px;
    background-position: 50% 101%;
  }

  .milk__item-title {
    font-size: 50px;
  }

  .milk__item-text {
    font-size: 34px;
  }

  .milk__composition {
    width: 500px;
    font-size: 19px;
    padding: 0 30px;
  }

  .milk__ingredients-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .milks__info {
    width: 390px;
    font-size: 20px;
  }

  .milk__img {
    width: 200px;
    height: auto;
  }

  .milk__img-info {
    top: -60px;
  }

  .milk__img-text {
    top: 346px;
    left: 46%;
    width: 100px;
    height: 100px;
  }

  .milk__img-good--size {
    width: 110px;
  }

  .milks__info--sec {
    width: 100%;
  }

  .milks__info--s {
    width: 322px;
    margin: 0 0 0 30px;
  }

  .milk__ingredients-title--th {
    margin-top: 0;
  }

  .ingredients-img {
    width: 320px;
    height: 190px;
    margin-left: 0;
  }

  .milk__ingredients-info--s {
    height: auto;
  }

  .milk__ingredients-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 30px;
  }

  .milk__ingredients-info--th {
    flex-direction: column;
  }

  .health-content .features {
    padding-bottom: 90px;
  }

}

@media (min-width: 1280px) {
  .health-content .container {
    width: 920px;
    padding: 0;
  }

  .health-content .features {
    padding-bottom: 90px;
  }

  .milks__info {
    width: 100%;
    font-size: 24px;
    line-height: 1.3;
  }

  .milks__info--s {
    width: 385px;
  }

  .milk__list-item {
    display: flex;
    justify-content: space-between;
    background: none;
    width: 920px;
    height: 714px;
    padding: 0;
    margin-bottom: 100px;
  }

  .milk__item-wrap {
    width: 460px;
    background: url("../img/health-content/milk-bg-dsk.png")no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
    margin-top: 0;
  }

  .milks__title-text {
    font-size: 24px;
    margin: 25px 0 80px 0;
  }

  .milk__item-title {
    font-size: 64px;
    margin-top: 44px;
  }

  .milk__item-text {
    font-size: 42px;
  }

  .milk__composition {
    width: auto;
    font-size: 20px;
    margin: 0 35px;
  }

  .milks__info--last {
    width: 390px !important;
  }

  .milk__img {
    width: auto;
    height: auto;
  }

  .milk__img-info {
    top: 0;
  }

  .milk__img-text {
    width: 141px;
    height: 141px;
    top: 436px;
    margin-left: -159px;
    left: 146%;
  }
  .milk__green {
    width: auto;
    font-size: 17px;
    z-index: 10;
  }

  .bold-text {
    font-size: 19px;
  }

  .milk__img-good {
    width: 230px;
    bottom: 31px;
    left: 63%;
    z-index: 20;
  }

  .milk__ingredients-box {
    width: 881px;
    margin-top: 41px;
    margin-bottom: 100px;
  }

  .milk__ingredients-info {
    width: 880px;
  }

  .milk__ingredients-info--s {
    width: 853px;
  }

  .milk__ingredients-title {
    margin-bottom: 40px;
  }

  .milk__ingredients-title--s {
    margin-top: 50px;
  }

  .milk__ingredients-info--th {
    width: 393px;
  }

  .milk__img-good--size {
    width: 160px;
  }

  .milk__list-item--reverse {
    flex-direction: row-reverse;
  }

  .milk__img--reverse {
    bottom: 14px;
    left: -87px;
  }

  .milk__img--funduk {
    left: 82%;
    bottom: 32px;

  }

  .milk__green--reverse {
    left: 51px;
    top: 461px;
  }

  .milks__info--fst {
    width: 419px;
  }

  .ingredients-img {
    width: 420px;
    height: 240px;
  }

  .ingredients-img--th {
    margin-top: 80px;
  }

}

@media (min-width: 300px) and (max-width: 768px){
  #na-gretski {
    width: 211px;
    //height: 34px;
  }
  .milk__item-title:first-child {
    margin-bottom: 0;
  }
}

.milk__img-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 23px;
  background: url("../img/welcome/soon.svg") no-repeat;
  width: 135px;
  height: 135px;
  position: absolute;
  top: 0;
  right: -80px;
  -webkit-background-size: contain;
  background-size: contain;

  @media (max-width: 1280px) {
    right: 52px;
  }

  @media (max-width: 767px) {
    width: 100px;
    height: 100px;
    font-size: 17px;
    right: -1px;
  }
}



.info-slider {
  display: block;
  position: relative;
  margin-bottom: -24px;
  z-index: 1;

}

.info-slider__slider {
  display: block;

  .owl-prev,
  .owl-next {
    display: block;
    position: absolute;
    top: 120px;
    font-size: 0 !important;
    width: 37px;
    height: 37px;
    border-radius: 50% !important;
    background-color: #ed2024 !important;

    @media (min-width: $tablet) {
      width: 56px;
      height: 56px;
      top: 220px;
    }

    @media (min-width: $laptop) {
      top: 400px;
    }

    @media (min-width: $desktop) {
      width: 60px;
      height: 60px;
    }
  }

  .owl-prev {
    left: 50%;
    margin-left: -145px;
    background-image: url("../img/where-buy/arrow-left.svg") !important;
    background-position: 40% center !important;
    background-repeat: no-repeat !important;
    background-size: 14px !important;

    @media (min-width: $tablet) {
      margin-left: -245px;
      //background-size: 60% 60% !important;
      background-size: 23px !important;
    }

    @media (min-width: 540px) {
      left: 5%;
      margin-left: 0;
    }

    @media (min-width: $laptop) {}

    @media (min-width: $desktop) {}
  }

  .owl-next {
    right: 50%;
    margin-right: -145px;
    background-image: url("../img/where-buy/arrow-right.svg") !important;
    background-position: 60% center !important;
    background-repeat: no-repeat !important;
    background-size: 14px !important;


    @media (min-width: $tablet) {
      margin-right: -245px;
      //background-size: 60% 60% !important;
      background-size: 23px !important;
    }

    @media (min-width: 540px) {
      right: 5%;
      margin-right: 0;
    }

    @media (min-width: $laptop) {}

    @media (min-width: $desktop) {}
  }

  .owl-dots {
    display: none;
  }
}

.info-slider__slide {

  display: block;
  position: relative;
  height: 495px;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: 21% -38%;
  background-color: #f1edec;

  &::after {
    //content: "";
    //display: block;
    //width: 100%;
    //height: 100%;
    //background-image: linear-gradient(transparent 0%, transparent 65%, #f1edec 81%, #f1edec 100%)
  }

  &--walnut {
    background-image: url("../img/info-slider/slide-1-bg.jpg");
  }

  &--almond {
    background-image: url("../img/info-slider/slide-2-bg.jpg");
  }

  &--avellana {
    background-image: url("../img/info-slider/slide-3-bg.jpg");
  }

  @media (min-width: $iphone) {
    background-position: 16% -38%;
  }

  @media (min-width: 420px) {
    background-position: 11% -38%;
  }

  @media (min-width: 500px) {
    background-position: 6% -38%;
  }

  @media (min-width: 540px) {
    background-position: 0 -38%;
  }

  @media (min-width: $tablet) {
    background-size: auto 660px;
    background-position: -50px -60px;
    height: 800px;

    &::after {
      background-image: linear-gradient(transparent 0%, transparent 67%, #f1edec 79%, #f1edec 100%);
    }
  }

  @media (min-width: 1024px) {
    height: 612px;

    &::after {
      display: none;
    }
  }

  @media (min-width: $laptop) {
    height: 850px;
    background-size: auto 920px;
    background-position: 0 -70px;
  }

  @media (min-width: $desktop) {
    &::after {
      display: block;
      background-image: linear-gradient(to right, transparent 0%, transparent 1840px, #f1edec 1875px, #f1edec 100%);
    }
  }

  @media (min-width: 2000px) {
    &::after {
      background-image: linear-gradient(to right, transparent 0%, transparent 67%, #f1edec 79%, #f1edec 100%);
    }

  }
}

.info-slider__slide-content {
  display: block;
  width: 180px;
  margin: 0 auto;
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: $tablet) {
    width: 220px;
    bottom: 110px;
  }

  @media (min-width: 1024px) {
    left: auto;
    bottom: auto;
    top: 220px;
    right: 20%;
    transform: none;
  }

  @media (min-width: $laptop) {
    width: 380px;
    top: 340px;
    right: 11%;
  }

  @media (min-width: $desktop) {
    width: 510px;
    top: 340px;

  }

  @media (min-width: 1750px) {
    right: 16%;
  }

  @media (min-width: $monitor) {
    top: 260px;
    right: 12%;
    width: 760px;
  }


}

.info-slider__slide-title {
  display: block;
  //margin-bottom: 10px;
  color: #ed2024;
  font-size: 32px;
  line-height: 1;
  @include drina();
  text-align: center;

  @media (min-width: $tablet) {
    font-size: 38px;
  }

  @media (min-width: $laptop) {
    font-size: 64px;
  }

  @media (min-width: $desktop) {
    font-size: 80px;
  }

  @media (min-width: $monitor) {
    font-size: 120px;
  }
}

.info-slider__slide-descr {
  display: block;
  @include drina();
  color: #653929;
  font-size: 24px;
  text-align: center;
  //height: 240px;

  @media (max-width: 1024px) {
    height: 66px;
  }

  @media (max-width: 1800px){
    //height: 160px;
  }
}

.info-slider__slide-text {
  position: absolute;
  right: 50%;
  margin-right: -140px;
  bottom: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  background-image: url("../img/info-slider/green.svg");
  background-size: contain;
  background-position: center center;
  color: #fff;
  @include ing();
  text-align: center;

  @media (min-width: 540px) {
    right: auto;
    margin-right: 0;
    left: 300px;
  }

  @media (min-width: $tablet) {
    width: 130px;
    height: 130px;
    left: 450px;
    font-size: 20px;
    bottom: 270px;
  }

  @media (min-width: 1024px) {
    bottom: 100px;
  }

  @media (min-width: $laptop) {
    width: 150px;
    height: 150px;
    bottom: 120px;
    left: 690px;
    font-size: 22px;
  }

  @media (min-width: $desktop) {
    width: 200px;
    height: 200px;
    bottom: 110px;
    left: 710px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
  }
}

.info-slider__slide-text-big {
  display: block;
  text-transform: uppercase;
  width: 124px;
  @include ingBold();
}

.info-slider__slide-soon {
  display: none;
  color: #00963F;
  font-size: 48px;
  font-weight: bold;
  position: absolute;
  left: 50%;

  @media (min-width: $laptop) {
    display: block;
    font-size: 32px;
    margin-left: -142px;
    bottom: -180px;
  }

  @media (min-width: $desktop) {
    display: block;
    font-size: 31px;
    bottom: -180px;
    margin-left: -132px;
  }

  @media (min-width: 1900px) {
    bottom: -286px;
  }
}

.info-slider__all-goods {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 40px;

  z-index: 2;
  color: #00963F;
  @include ingBold();
  text-align: center;
  font-size: 15px;
  text-decoration: underline;

  @media (min-width: $tablet) {
    font-size: 20px;
    bottom: 60px;
  }

  @media (min-width: 1024px) {
    display: none;
  }


}

.info-slider #mind,
.info-slider #funduk {
  width: 25px;
  left: 18px;
  position: absolute;

  @media (min-width: 1900px) {
    width: 712px;
    left: 37px;
    top: 137px;
  }

  @media (max-width: 1899px) {
    width: 387px;
    left: 73px;
    top: 50px;
  }

  @media (max-width: 1440px) {
    width: 310px;
    left: 38px;
    top: 24px;
  }

  @media (max-width: 1279px) {
    width: 184px;
    left: 23px;
    top: -32px;
  }

  @media (max-width: 768px) {
    width: 161px;
    left: 12px;
    top: -46px;
  }
}

.info-slider #gretski {
  position: absolute;
  @media (min-width: 1900px) {
    width: 690px;
    left: 37px;
    top: 137px;
  }
  @media (max-width: 1899px) {
    width: 327px;
    left: 95px;
    top: 40px;
  }

  @media (max-width: 1440px) {
    width: 310px;
    left: 38px;
    top: 24px;
  }

  @media (max-width: 1280px) {
    width: 184px;
    left: 23px;
    top: -32px;
  }

  @media (max-width: 768px) {
    width: 136px;
    left: 23px;
    top: -49px;
  }
}


.info-tabs {
  display: block;
  padding-bottom: 30px;
  @include ing();
  font-size: 15.5px;
  color: #653929;

  @media (min-width: $tablet) {
    font-size: 16px;
  }

  @media (min-width: $laptop) {
    padding-bottom: 100px;
    font-size: 20px;
  }

  @media (min-width: $desktop) {
    font-size: 24px;
  }
}

.info-tabs__tab {
  display: none;

  &--active {
    display: block;
  }
}

.info-tabs__descr {
  display: block;
  margin-bottom: 30px;
  margin-right: -10px;
  line-height: 1.4;

  @media (min-width: $laptop) {
    margin-right: 0;
    margin-bottom: 45px;
  }

  @media (min-width: $desktop) {
    margin-right: -20px;
  }
}

.info-tabs__link {
  display: block;
  color: #00963f;

  &:hover,
  &:focus,
  &:active {
    color: #00963f;
  }
}

.info-tabs__vitamins {
  display: block;
  padding-left: 80px;
  margin-bottom: 36px;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 67px;
    height: 60px;
    position: absolute;
    top: 50%;
    margin-top: -30px;
    left: 0;
    background-image: url("../img/info-tabs/health.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  @media (min-width: $laptop) {
    margin-bottom: 45px;
    padding-left: 110px;

    &::before {
      width: 90px;
      height: 80px;
      margin-top: -40px;
    }
  }

  @media (min-width: $desktop) {
    padding-left: 160px;
    padding-top: 15px;
    padding-bottom: 15px;

    &::before {
      width: 135px;
      height: 120px;
      margin-top: -60px;
    }
  }
}

.info-tabs__vitamins-text {
  display: block;
  color: #00963f;
  font-size: 20px;

  &:first-child {
    margin-bottom: 8px;
  }

  @media (min-width: $tablet) {
    font-size: 24px;
  }

  @media (min-width: $laptop) {
    font-size: 30px;
  }

  @media (min-width: $desktop) {
    font-size: 36px;

    &:first-child {
      margin-bottom: 12px;
    }
  }
}

.info-tabs__info {
  display: block;
}

.info-tabs__composition {
  display: block;
}

.info-tabs__title {
  display: block;
  margin-bottom: 5px;
  color: #653929;
  font-size: 16px;

  @media (min-width: $tablet) {
    font-size: 16px;
  }

  @media (min-width: $laptop) {
    font-size: 20px;
  }

  @media (min-width: $desktop) {
    font-size: 24px;
  }
}

.info-tabs__descr {
  display: block;
}

.info-tabs__energy-title {
  display: block;
  margin-right: -10px;
  margin-bottom: 30px;

  @media (min-width: $laptop) {
    width: 84%;
    margin-right: 0;
    margin-bottom: 45px;
  }
}

.info-tabs__energy-value {
  display: block;
  margin-right: -17px;
  margin-bottom: 30px;

  @media (min-width: $laptop) {

    margin-right: 0;
    margin-bottom: 45px;
  }
}

.info-tabs__consist {
  display: block;

  @media (min-width: $tablet) {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: $desktop) {
    width: 100%;
  }
}

.info-tabs__consist-list {
  display: block;
  margin-bottom: 30px;
}

.info-tabs__consist-item {
  display: block;

  b {
    display: inline;
  }

  span {
    display: inline;
  }
}

.info-tabs__consist-item {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (min-width: $laptop) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}

.info-tabs__consist-other-list {
  display: block;
  padding-left: 5px;

  @media (min-width: $laptop) {
    padding-left: 8px;
  }
}

.info-tabs__consist-other-item {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (min-width: $laptop) {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}
.page-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 183px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: -22px;
  background-color: #773713;
  padding: 0 20px;

  &--brand {
    background-image: url("../img/page-header/brand.jpg");
    background-size: auto 130%;
    background-position: 45% 120%;


    @media (min-width: $tablet) {
      background-position: center center;
    }

    @media (min-width: $laptop) {
      background-size: auto;
    }
  }

  &--health {
    background-image: url("../img/page-header/bg-3.jpg");
    position: relative;
    background-position: 40% center;


    @media (min-width: $iphone) {
      background-size: auto 98%;
      background-position: 24% -22%;
    }

    @media (min-width: $tablet) {
      background-position: center center;
    }

    @media (min-width: $laptop) {
      background-size: auto 100%;
    }
  }

  &--health::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(124, 39, 5, 0.7)0%, rgba(237, 2, 2, 0.2) 100%);
    z-index: 2;
  }

  &--faq {
    background-image: url("../img/page-header/faq.jpg");


    @media (min-width: $iphone) {
      background-size: auto 100%;
      background-position: center center;
    }

    @media (min-width: $tablet) {
      background-position: center center;
    }

    @media (min-width: $laptop) {
      background-size: auto;
    }
  }

  &--recipes {
    position: relative;
    background-image: url("../img/page-header/recipes-bg-mob.jpg");
    background-size: auto 100%;
    background-position: 40% 50%;

    @media (min-width: $iphone) {
      background-image: url("../img/page-header/recipes.jpg");
      background-size: auto 130%;
      background-position: 45% 120%;
    }

    @media (min-width: $tablet) {
      background-position: center center;
    }

    @media (min-width: $laptop) {
      background-size: auto 102%;
    }
  }


  @media (min-width: $tablet) {
    height: 320px;

  }

  @media (min-width: $laptop) {
    height: 500px;


  }

  @media (min-width: 1921px) {
    height: 600px;
    background-size: 100% 100%;
  }

  &--recipes::before {
    content: '';
    width: 144%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(124, 39, 5, 0.7)0%, rgba(237, 2, 2, 0.2) 100%);
    z-index: 2;
  }

}

.page-header__title {
  display: block;
  margin-bottom: 20px;
  color: #fff;
  @include ingBold();
  text-shadow: 0px 2px 1px #653929;
  font-size: 36px;
  line-height: 1;
  text-align: center;
  position: relative;
  z-index: 10;

  @media (min-width: $tablet) {
    font-size: 55px;
  }

  @media (min-width: $laptop) {
    font-size: 75px;
    text-shadow: 0px 3px 2px #653929;
  }
}
.products {
  display: block;
  position: relative;
  z-index: 1;
  padding-bottom: 20px;





  @media (min-width: $tablet) {
    padding-top: 25px;
  }

  @media (min-width: $laptop) {
    padding-top: 55px;
  }
}

.products__title {
  margin-bottom: 20px;

  @media (min-width: $tablet) {
    margin-bottom: 30px;
  }

  @media (min-width: $laptop) {
    margin-bottom: 50px;
  }
}

.products__features {
  display: block;

  @media (min-width: $laptop) {
    margin-bottom: 30px;
  }
}

.products__info {
  display: block;
}

.products__text {
  display: block;
}
.recipe-page {
  display: block;
  @include ing();
  color: #653929;

  section {
    padding-top: 20px;
    padding-bottom: 75px;
  }

  @media (min-width: $tablet) {
    margin-top: -20px;

    section {
      padding-top: 50px;
    }
  }

  @media (min-width: $laptop) {
    margin-top: 0;
    font-size: 20px;

    section {
      padding-top: 70px;
    }
  }
}

.recipe-page__img {
  display: block;
  height: 215px;
  background-size: 100% 100%;
  margin-bottom: -15px;

  @media (min-width: $iphone) {
    background-size: cover;
    background-position: center center;
  }

  @media (min-width: $tablet) {
    display: none;
  }


}

.recipe-page__title {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  @include ingBold();
  color: #653929;
  text-transform: uppercase;
  font-size: 18px;

  @media (min-width: $laptop) {
    font-size: 30px;
  }
}

.recipe-page__info {
  display: block;

  @media (min-width: $laptop) {
    position: relative;
    height: 700px;
  }
}
.recipe-page__info--salad {

  @media (min-width: $laptop) {
    height: 825px;
  }
}

.recipe-page__info--no-milk {

  @media (min-width: $laptop) {
    height: 903px;
  }
}
.recipe-page__info-title {
  display: block;
  margin-bottom: 10px;
  color: #ed2024;
  font-size: 14px;
  text-align: center;
  @include ingBold();

  @media (min-width: $laptop) {
    font-size: 30px;
  }
}

.recipe-page__info-text {
  display: block;

  @media (min-width: $laptop) {
    position: absolute;
    width: 460px;
    min-height: 520px;
    top: 130px;
    left: 50%;
    margin-left: -340px;
    background-image: url("../img/recipe-page/ingredients-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    z-index: 2;
    padding: 63px 35px 7px 35px;
  }
}

.recipe-page__info-text--salad {
  @media (min-width: $laptop) {
    min-height: 680px;
  }
}

.recipe-page__info-text--no-milk {
  @media (min-width: $laptop) {
    min-height: 753px;
  }
}

.recipe-page__content {
  display: block;
  width: 250px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 18px;

  a {
    color: #00963f;
    text-decoration: underline;
  }

  @media (min-width: $laptop) {
    width: 400px;
    font-size: 20px;
    line-height: 28px;

  }
}

.recipe-page__info-pic {
  display: none;

  @media (min-width: $laptop) {
    display: block;
    width: 624px;
    height: 421px;
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -50px;
  }
}

.recipe-page__instruction {
  display: block;
  font-size: 14px;
}

.recipe-page__subtitle {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: center;
  @include ingBold();
  font-size: 14px;

  &--big {
    margin-bottom: 20px;
    font-size: 18px;
  }

  @media (min-width: $laptop) {
    font-size: 30px;
    margin-bottom: 40px;

    &--big {
      max-width: 740px;
      margin-bottom: 45px;
      font-size: 30px;
    }
  }
}

.recipe-page__instruction-steps {
  display: block;
  width: 320px;
  margin: 0 auto;
  margin-bottom: 45px;
  padding-top: 30px;
  padding-bottom: 81px;
  background-image: url("../img/recipe-page/bg.svg");
  background-size: cover;
  background-position: center;

  @media (min-width: $laptop) {
    width: 800px;
    padding: 60px;
    padding-bottom: 130px;
    background-image: url("../img/recipe-page/bg-big.png");
    background-size: 100% 100%;
  }
}

.recipe-page__instruction-step {
  display: block;
  margin-bottom: 20px;

  @media (min-width: $laptop) {
    margin-bottom: 40px;
  }
}

.recipe-page__inctruction-subtitle {
  display: block;
  margin-bottom: 10px;
  text-align: center;
  color: #00963f;
  @include ingBold();

  @media (min-width: $laptop) {
    margin-bottom: 15px;
    font-size: 30px;
    text-align: left;
  }
}

.recipe-page__instruction-text {
  display: block;
  width: 255px;
  margin: 0 auto;
  font-size: 15px;

  @media (min-width: $laptop) {
    width: 100%;
    font-size: 20px;
  }
}

.recipe-page__other {
  display: block;

  @media (min-width: $laptop) {
    margin-bottom: 100px;
  }
}

.recipe-page__other-list {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  @media (min-width: $laptop) {
    flex-direction: row;
    justify-content: space-between;
    width: 1160px;
    margin: 0 auto;
  }
}

.recipe-page__other-item {
  display: block;
  margin-bottom: 25px;
}

.recipe-page__applying {
  display: block;
}

.recipe-page__applying__content {
  display: block;
  max-width: 260px;
  margin: 0 auto;

  @media (min-width: $laptop) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1100px;
  }
}

.ice-cream-link {
  color: #00963f;;
}
.recipes-content {
  display: block;
  padding-top: 25px;
  padding-bottom: 60px;
  @include ing();
  color: #653929;

  @media (min-width: $laptop) {
    padding-top: 50px;
    padding-bottom: 120px;

    .container {
      width: 1160px;
      padding: 0;
    }
  }
}

.recipes-content__title {
  display: block;
  margin-bottom: 30px;
  text-align: center;
  color: #653929;
  font-size: 18px;
  @include ingBold();

  @media (min-width: $tablet) {
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 40px;
    font-size: 24px;
  }

  @media (min-width: $laptop) {
    max-width: 100%;
    margin-bottom: 50px;
    font-size: 30px;
  }
}

.recipes-content__ways {
  display: block;
  padding-bottom: 25px;

  @media (min-width: $tablet) {
    max-width: 480px;
    margin: 0 auto;
  }

  @media (min-width: $laptop) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
  }
}

.recipes-content__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: $tablet) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: $laptop) {
    justify-content: flex-start;

  }
}

.recipes-content__item {
  display: block;
  margin-bottom: 25px;

  @media (min-width: $tablet) {
    margin-right: 25px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: $laptop) {
    margin-right: 40px;
    margin-bottom: 40px;

    &:nth-child(2n) {
      margin-right: 40px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.recipes-content__link {
  display: block;
  text-decoration: none;
}
.recipe-item {
  display: block;
  width: 230px;
  height: 275px;
  background: #FFFFFF;
  border-radius: 25px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;

  @media (min-width: $laptop) {
    width: 360px;
    height: 437px;
    border-radius: 50px;
  }
}

.recipe-item:hover {
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.2);
}

.recipe-item__photo {
  display: block;
  height: 170px;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  @media (min-width: $laptop) {
    height: 267px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
}

.recipe-item__content {
  display: block;
  padding: 23px;

  @media (min-width: $laptop) {
    padding: 37px;
    padding-right: 45px;
  }
}

.recipe-item__text {
  display: block;
  @include ingBold();
  text-transform: uppercase;
  font-size: 16px;
  color: #653929;

  @media (min-width: $laptop) {
    font-size: 23px;
  }
}
.welcome {
  display: block;
  height: 367px;
  background-image: url("../img/welcome/welcome-bg.png");
  background-size: auto 100%;
  background-position: 44% bottom;
  position: relative;
  color: #fff;
  margin-bottom: -23px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(180deg, rgba(121, 27, 5, 0.1) 0%, rgba(147, 34, 7, 0) 100%);
  }

  @media (min-width: $tablet) {
    height: 630px;
  }

  @media (min-width: $laptop) {
    height: 890px;
    background-size: auto 100%;
    background-position: top center;

    &::after {
      display: none;
    }
  }

  @media (min-width: $monitor) {
    height: 950px;
    background-size: 100% 100%;
    background-position: top center;
  }
}

.welcome__content {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -190px;
  bottom: 35px;
  z-index: 1;

  @media (min-width: $tablet) {
    margin-left: -370px;
    bottom: 50px;
  }

  @media (min-width: $laptop) {
    margin-left: -540px;
  }
}

.welcome__title {
  display: block;
  margin: 0 auto;
  font-size: 34px;
  text-align: center;
  text-shadow: 1px 1px 2px black;
  margin-left: -80px;
  @include drina();

  @media (min-width: $tablet) {
    font-size: 60px;
    text-shadow: 2px 2px 3px black;
    margin-left: -140px;
    line-height: 1;
  }

  @media (min-width: $laptop) {
    font-size: 80px;
    margin-bottom: 15px;
    margin-left: -270px;
  }

  @media (min-width: $desktop) {
    margin-bottom: 50px;

    br {
      display: none;
    }
  }

  @media (min-width: $monitor) {
    margin-bottom: 70px;
    font-size: 100px;
  }
}

.welcome__images {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
}

.welcome__image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  position: relative;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.015);
  }
}

.welcome__milk {
  display: block;
  width: 57px;
  height: 117px;
  background-image: url("../img/welcome/moloko.png");
  background-size: contain;
  position: absolute;
  left: -10px;
  bottom: 0;
  z-index: 5;

  @media (min-width: $tablet) {
    width: 100px;
    height: 200px;
  }

  @media (min-width: $laptop) {
    width: 175px;
    height: 355px;
    left: -50px;
  }
}

.welcome__walnut {
  display: block;
  background-image: url("../img/welcome/walnut.png");
  left: 0;
  width: 140px;
  height: 211px;
  z-index: 3;

  @media (min-width: $tablet) {
    width: 267px;
    height: 413px;
  }

  @media (min-width: $laptop) {
    width: 400px;
    height: 609px;
  }
}

.welcome__almond {
  display: block;
  background-image: url("../img/welcome/almond.png");
  left: -44px;
  width: 156px;
  height: 226px;
  z-index: 2;

  @media (min-width: $tablet) {
    width: 298px;
    height: 420px;
    left: -80px;
  }

  @media (min-width: $laptop) {
    width: 451px;
    height: 609px;
    left: -135px;
  }
}

.welcome__avellana {
  display: block;
  background-image: url("../img/welcome/avellana.png");
  left: -111px;
  width: 176px;
  height: 232px;
  z-index: 1;

  @media (min-width: $tablet) {
    width: 332px;
    height: 415px;
    left: -205px;
  }

  @media (min-width: $laptop) {
    width: 506px;
    height: 609px;
    left: -335px;
  }
}

.welcome__info {
  display: block;
  position: absolute;
  font-size: 7px;
  @include ing();
  text-align: center;
  max-width: 47px;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  &--walnut {
    display: block;
    top: 135px;
    left: 47px;
  }

  &--almond {
    display: block;
    top: 162px;
    right: 14px;
  }

  &--avellana {
    display: block;
    top: 167px;
    right: 14px;
  }

  @media (min-width: $tablet) {
    font-size: 11px;
    max-width: 70px;

    &--walnut {
      top: 268px;
      left: 99px;
    }

    &--almond {
      top: 297px;
      right: 30px;
    }

    &--avellana {
      top: 297px;
      right: 30px;
    }
  }

  @media (min-width: $laptop) {
    font-size: 18px;
    max-width: 120px;

    &--walnut {
      top: 383px;
      left: 142px;
    }

    &--almond {
      top: 418px;
      right: 30px;
    }

    &--avellana {
      top: 418px;
      right: 30px;
    }
  }
}

.welcome__info-big {
  text-transform: uppercase;
  line-height: 1;


  @media (min-width: $tablet) {
    padding-top: 2px;
  }

  @media (min-width: $laptop) {
    font-size: 22px;
    @include ingBold();
  }
}

.welcome__soon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background-image: url("../img/welcome/soon.svg");
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  width: 60px;
  height: 60px;
  font-size: 9px;
  color: #fff;
  top: 5px;
  right: 12px;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  @media (min-width: $tablet) {
    width: 108px;
    height: 108px;
    font-size: 16px;
    top: -12px;
  }

  @media (min-width: $laptop) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    position: absolute;
    right: 0;
    top: 30%;
    width: 132px;
    height: 132px;
    font-size: 22px;
    @include ing();
    background-image: url("../img/welcome/soon.svg");
    z-index: 6;
  }
}

.welcome__soon--info {
  top: -186px;
  right: 30px;
  width: 80px;
  height: 80px;
  font-size: 13px;

  @media (min-width: 768px) {
    width: 110px;
    height: 110px;
    font-size: 17px;
    top: -334px;
  }

  @media (min-width: 1280px) {
    //width: 136px;
    //height: 136px;
    //font-size: 21px;
    //top: -443px;
    //right: 0;
    display: none;
  }

  @media (min-width: 1440px) {

  }
}

#gorst {
  @media (min-width: 300px) {
    display: none;
  }

  @media (min-width: 500px) {

  }

  @media (min-width: 768px) {
    display: inline-block;
    width: 640px;
  }

  @media (min-width: 1280px) {
    display: inline-block;
    width: 774px;
  }

  @media (min-width: 1440px) {
    display: inline-block;

  }

  @media (min-width: 1900px) {
    display: inline-block;
    width: 1185px;
  }

}

#gorst-mob {
  @media (min-width: 300px) {
    display: inline-block;
    width: 240px;
  }

  @media (min-width: 768px) {
    display: none;
  }
}
.where-buy {
  display: block;
  padding-top: 15px;
  padding-bottom: 40px;
  background-color: #fbf4e9;

  @media (min-width: $laptop) {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  @media (min-width: $desktop) {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

.where-buy__title {
  display: block;
  margin-bottom: 25px;

  @media (min-width: $laptop) {
    margin-bottom: 30px;
  }

  @media (min-width: $desktop) {
    margin-bottom: 37px;
  }
}

.where-buy__slider {
  display: block;
  position: relative;

  @media (min-width: $tablet) {
    max-width: 410px;
    margin: 0 auto;
  }

  @media (min-width: $laptop) {
    max-width: 620px;
  }

  @media (min-width: $desktop) {
    max-width: 1040px;
  }



  .owl-prev,
  .owl-next {
    display: block;
    position: absolute;
    top: 80px;
    font-size: 0 !important;
    width: 37px;
    height: 37px;
    border-radius: 50% !important;
    background-color: #ed2024 !important;

    @media (min-width: $desktop) {
      width: 56px;
      height: 56px;
    }
  }

  .owl-prev {
    left: 50%;
    margin-left: -145px;
    background-image: url("../img/where-buy/arrow-left.svg") !important;
    background-position: 35% center !important;
    background-repeat: no-repeat !important;
    background-size: 45% !important;

    @media (min-width: $tablet) {
      margin-left: -245px;
    }

    @media (min-width: $laptop) {
      margin-left: -345px;
    }

    @media (min-width: $desktop) {
      margin-left: -600px;
      background-size: 60% 60% !important;
    }
  }

  .owl-next {
    right: 50%;
    margin-right: -145px;
    background-image: url("../img/where-buy/arrow-right.svg") !important;
    background-position: 65% center !important;
    background-repeat: no-repeat !important;
    background-size: 45% !important;

    @media (min-width: $tablet) {
      margin-right: -245px;
    }

    @media (min-width: $laptop) {
      margin-right: -345px;
    }

    @media (min-width: $desktop) {
      margin-right: -600px;
      background-size: 60% 60% !important;
    }
  }

  .owl-dots {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .owl-dot {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    margin-left: 5px;
    background: #a75214 !important;
    border-radius: 10px !important;
    opacity: 0.5;
    transition: 0.3s;

    &.active {
      opacity: 1;
    }
  }
}

.where-buy__slide {
  display: block;
  margin: 0 auto;
  min-height: 225px;
  width: 173px;
  margin-bottom: 20px;
  background-color: #fff;
}

.where-buy__market {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 140px;
}

.where-buy__image {
  display: block;
  width: auto !important;
}

.where-buy__descr {
  display: block;
  text-align: center;
  color: rgba(#653929, 0.5);
  font-size: 18px;
}
._template {
  display: block;
}