@font-face {
    font-family: 'inglobal';
    src: url('../fonts/inglobal-BoldItalic.eot');
    src: url('../fonts/inglobal-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/inglobal-BoldItalic.woff2') format('woff2'),
        url('../fonts/inglobal-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'inglobal';
    src: url('../fonts/inglobal-Bold.eot');
    src: url('../fonts/inglobal-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/inglobal-Bold.woff2') format('woff2'),
        url('../fonts/inglobal-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'inglobal';
    src: url('../fonts/inglobal.eot');
    src: url('../fonts/inglobal.eot?#iefix') format('embedded-opentype'),
        url('../fonts/inglobal.woff2') format('woff2'),
        url('../fonts/inglobal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inglobal';
    src: url('../fonts/inglobal-Italic.eot');
    src: url('../fonts/inglobal-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/inglobal-Italic.woff2') format('woff2'),
        url('../fonts/inglobal-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Drina';
    src: url('../fonts/Drina.eot');
    src: url('../fonts/Drina.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Drina.woff2') format('woff2'),
        url('../fonts/Drina.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Var for using custom font
$CustomFont: 'inglobal',
Helvetica,
Arial,
sans-serif;

@mixin ing {
    font-family: 'inglobal';
    font-weight: normal;
    font-style: normal;
}

@mixin ingBold {
    font-family: 'inglobal';
    font-weight: bold;
    font-style: normal;
}

@mixin ingItal {
    font-family: 'inglobal';
    font-weight: normal;
    font-style: italic;
}

@mixin ingBoldItal {
    font-family: 'inglobal';
    font-weight: bold;
    font-style: italic;
}

@mixin drina {
    font-family: 'Drina';
    font-weight: normal;
    font-style: normal;
}